import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatTableDataSource } from '@angular/material';
//import { AdeudosComponent } from 'src/app/paginas/mis-adeudos/adeudos.cruces/cruces.componentomponent';
import {AdeudosCrucesComponent} from '../paginas/adeudos/adeudos-cruces/adeudos-cruces.component';//'../paginas/mis-adeudos/adeudos-cruces/adeudos-cruces.component';
import { DebtsService } from 'src/app/servicios/debts.service';
import * as moment from 'moment';
import { AppService } from '../servicios/app.service';


@Component ({
    selector:'app-cruces-dialogo',
    template: `
    <h1 mat-dialog-title class="text-accent text-center">Cruces</h1>
    <hr>
    <div mat-dialog-content>
        <div class="mat-table-container">
            <table mat-table [dataSource]="cruces" multiTemplateDataRows class="mat-table-fullwidth">
                <ng-container matColumnDef="Fecha">
                    <th mat-header-cell *matHeaderCellDef class="mat-table-header"> Fecha </th>
                    <td mat-cell *matCellDef="let elemento"><small> {{elemento?.FechaCruce | date}} </small></td>
                </ng-container>
                <ng-container matColumnDef="Plaza">
                    <th mat-header-cell *matHeaderCellDef class="mat-table-header"> Plaza </th>
                    <td mat-cell *matCellDef="let elemento"><small> {{elemento?.Plaza }} </small></td>
                </ng-container>
                <ng-container matColumnDef="Tramo">
                    <th mat-header-cell *matHeaderCellDef class="mat-table-header"> Tramo </th>
                    <td mat-cell *matCellDef="let elemento"><small> {{ elemento?.TramoInicial }}-{{ elemento?.TramoFinal }} </small></td>
                </ng-container>
                <ng-container matColumnDef="Tarjeta">
                    <th mat-header-cell *matHeaderCellDef class="mat-table-header"> Tarjeta </th>
                    <td mat-cell *matCellDef="let elemento"><small> {{ elemento?.NumeroTag}}  </small></td>
                </ng-container>
                <ng-container matColumnDef="NumeroEconomico">
                    <th mat-header-cell *matHeaderCellDef class="mat-table-header"> Económico </th>
                    <td mat-cell *matCellDef="let elemento"><small> {{ elemento?.NumeroEconomico}}  </small></td>
                </ng-container>
                <ng-container matColumnDef="ClaseVehiculo">
                    <th mat-header-cell *matHeaderCellDef class="mat-table-header"> Clasifiación</th>
                    <td mat-cell *matCellDef="let elemento"><small> {{ elemento?.ClaveVehiculo}} - {{ elemento?.DescripcionClave}} </small></td>
                </ng-container>
                <ng-container matColumnDef="Importe">
                    <th mat-header-cell *matHeaderCellDef class="mat-table-header"> Importe </th>
                    <td mat-cell *matCellDef="let elemento"><small> {{ elemento?.Tarifa | currency : 'MXN' : '$'}} MXN</small></td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="columnas"></tr>
                <tr mat-row *matRowDef="let elemento; columns: columnas;"></tr>
            </table>
            <mat-paginator [length]="total"  (page)="cambioNumeroRegistros($event)" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons></mat-paginator>            
        </div>
    </div>
    <hr>
    <div mat-dialog-actions class="text-center">
    <button mat-button cdkFocusInitial (click)="cerrar()" class="text-secondary">Cancelar</button>
    <button mat-button class="btn-submit-dialog" *ngIf="cruces.data.length > 0" (click)="descargarArchivoExcel()">
                        <i class="material-icons va-middle" style="font-size: 14px">save</i>
                        Exportar
     </button>
    </div>
  `,
  providers: [ DebtsService, AppService ]
})

export class CrucesDialogComponent {

    @ViewChild(MatPaginator) paginador: MatPaginator;
    columnas: string[] = ['Fecha','Plaza','Tramo','Tarjeta','NumeroEconomico','ClaseVehiculo','Importe'];
    cruces =  new MatTableDataSource([]);
    total: number = 0;
    tablaConfiguracion: any = { pagina: 1, tamano: 10, index: 0 };
    IdEstadoCuenta:number;    
    columnasXLS: string[] = ['FechaMovimiento', 'NumeroTag', 'Plaza','NumeroEconomico', 'ClaseVehiculo', 'Monto', 'Carril', 'Cuerpo'];
    
    constructor(public dialogRef: MatDialogRef<AdeudosCrucesComponent>,
        private debtsService: DebtsService,
        private appServicio: AppService,
        @Inject(MAT_DIALOG_DATA) public data: any) {

            this.IdEstadoCuenta = data.usu.IdEstadoCuenta;
            this.iniciarDialogo(this.IdEstadoCuenta,this.tablaConfiguracion.pagina,this.tablaConfiguracion.tamano);
        }

    iniciarDialogo(idEstadoCuenta:number, pagina:number, registrosPorPagina:number){

        const parametros={
            IdEstadoCuenta: idEstadoCuenta,
            pagina: pagina,
            RegistrosPorPagina: registrosPorPagina
        };
        this.debtsService.obtenerCrucesPorEstadoCuenta(parametros)
        .then((respuesta)=>{
    
            if(respuesta.ListaCruces.length>0){
                this.cruces.data=respuesta.ListaCruces;
                this.total = respuesta.TotalCruces;
            }
        }).catch((error) => {
            this.cruces.data = [];
            this.total = 0;
            this.dialogRef.close({ error });
        });
    }

    cambioNumeroRegistros(evento: any) {

        this.tablaConfiguracion.index = evento.pageIndex;
        this.tablaConfiguracion.tamano = evento.pageSize;
        this.tablaConfiguracion.pagina = evento.pageIndex + 1;
        this.iniciarDialogo(this.IdEstadoCuenta,this.tablaConfiguracion.pagina,this.tablaConfiguracion.tamano);
      }
      
    descargarArchivoExcel(): void {

        let dataCsv: Array<any> = [];
        var IdTag = 0;
        const parametros={
            IdEstadoCuenta: this.IdEstadoCuenta,
            pagina: 1,
            RegistrosPorPagina: this.total
        };
        this.debtsService.obtenerCrucesPorEstadoCuenta(parametros)
        .then((respuesta)=>{
    
            if(respuesta.ListaCruces.length>0){
              
                respuesta.ListaCruces.forEach((cruce) => {


                    const payload = {
                        Operador: cruce.Operador,
                        Plaza: cruce.Plaza,
                        FechaMovimiento: cruce.FechaHoraCruce,
                        NumeroTag: cruce.NumeroTag,
                        NumeroEconomico: cruce.NumeroEconomico,
                        ClaseVehiculo: `${cruce.ClaveVehiculo} - ${cruce.DescripcionClave}`,
                        Monto: `$${parseFloat(cruce.Tarifa).toFixed(2)}`,
                        Carril: cruce.NumeroCarrilPlaza,
                        Cuerpo: cruce.Cuerpo
                    }

                    dataCsv.push(payload);
                });

                var Nombre = `Cruces_${moment(new Date()).format("YYMMDDHHmmss").toString()}`;
                this.appServicio.descargarArchivoCSV(dataCsv,Nombre,this.columnasXLS)
            }
        }).catch((error) => {
            this.cruces.data = [];
            this.total = 0;
            this.dialogRef.close({ error });
        });
    }

    cerrar(): void {

    this.dialogRef.close();

    }
}