import { Component, OnInit, ViewChild } from '@angular/core';
import { ReloadService } from 'src/app/servicios/reload.service';
import { ReloadDialogComponent } from 'src/app/dialogos/reload.dialog';
import { SharedService } from 'src/app/servicios/shared.service';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { AppService } from 'src/app/servicios/app.service'; //'src/app/servicios/app.service';
import * as moment from 'moment';

@Component({
  selector: 'app-recarga',
  templateUrl: './reload.component.html',
  providers: [ ReloadService, AppService ]
})

export class ReloadComponent implements OnInit {

  @ViewChild(MatPaginator) paginador: MatPaginator;
  tarjetas = new MatTableDataSource([]);
  //tarjetas:any;
  cargando: boolean = true;
  columnas: string[] = ["Tag","Marca","Modelo","Anio","Placa","EstatusTag","Saldo","Acciones"];
  totalRegistros = 0;
  peticionNumero: number = 0;
  tablaConfiguracion: any = { pagina: 1, tamano: 10, index: 0 };
  formularioBusqueda: FormGroup;
  columnasXLS: string[] = ["Número Tag","Marca","Modelo","Anio","Placa","Estatus Tag","Saldo"];

  constructor(private recargaServicio: ReloadService,
              private compartidoServicio: SharedService,
              public dialogo: MatDialog,
              private appServicio: AppService
              ) { }

  ngOnInit() {

    this.inicializarFormulario();
    this.obtenerDatos();    
    setTimeout(() => this.tarjetas.paginator = this.paginador);
  }

  inicializarFormulario(){

    this.formularioBusqueda = new FormGroup({
      tarjeta: new FormControl(),
      placa: new FormControl(),
   });

  }
  obtenerDatos() {

    const payload = {
      Tag: this.formularioBusqueda.controls.tarjeta.value ? this.formularioBusqueda.controls.tarjeta.value : '0',
      Placa: this.formularioBusqueda.controls.placa.value ? this.formularioBusqueda.controls.placa.value : '0',
    }

    this.recargaServicio
        .obtener(payload)
        .then((respuesta) => {

          this.tarjetas.data = respuesta;
          this.cargando = false;

        })
        .catch((error) => {

          this.cargando = false;

        });

  }
  cambioNumeroRegistros(evento: any) {
  
      this.tablaConfiguracion.index = evento.pageIndex;
      this.tablaConfiguracion.tamano = evento.pageSize;
      this.tablaConfiguracion.pagina = evento.pageIndex + 1;
      this.obtenerDatos();
  
  }
  desactivarTag(elemento){
  
    console.log(elemento);
    this.compartidoServicio
    .presentarDialogoConfirmacion('¿Seguro desea deshabilitar este Tag?')
    .subscribe((respuesta) => {
  
        if (respuesta) {
  
            this.recargaServicio
                .DesactivaTag(elemento.IdTag)
                .then(() => {
  
                  this.obtenerDatos();
                    this.compartidoServicio.presentarDialogoSatisfactorio('Tag Deshabilitado');        
                              
                })
                .catch((error) => this.compartidoServicio.presentarDialogoErroneo(error));
        }
    });
  
  }
  activarTag(elemento){
  
    this.compartidoServicio
    .presentarDialogoConfirmacion('¿Seguro desea habilitar este Tag?')
    .subscribe((respuesta) => {
  
        if (respuesta) {
  
            this.recargaServicio
                .ActivarTag(elemento.IdTag)
                .then(() => {
  
                  this.obtenerDatos();
                    this.compartidoServicio.presentarDialogoSatisfactorio('Tag Habilitado');                  
                })
                .catch((error) => this.compartidoServicio.presentarDialogoErroneo(error));
        }
    });
  
  }
  mostarDialogoRecarga(elemento: any): void {
  
      const props = { tarjeta: elemento }
      const refDialogo = this.dialogo.open(ReloadDialogComponent, { data: props, width: '500px', disableClose: true});
  
      refDialogo.afterClosed().subscribe((resultado) => {
  
          if (!resultado) { return; }
          if (resultado.error) { this.compartidoServicio.presentarDialogoErroneo(resultado.error); }
          if (resultado.respuesta) {
              this.obtenerDatos();
              this.compartidoServicio.presentarDialogoSatisfactorio('Recarga realizada correctamente');
          }
  
      });
  
  
  }
  descargarArchivoExcel(): any {
    let dataCsv: Array<any> = [];
    this.cargando = true;
    var IdTag = 0;
    const parametros = {      
      pagina: 1,
      limite: this.totalRegistros,
      Tag: this.formularioBusqueda.controls.tarjeta.value ? this.formularioBusqueda.controls.tarjeta.value : '0',
      Placa: this.formularioBusqueda.controls.placa.value ? this.formularioBusqueda.controls.placa.value : '0',
    };
    this.recargaServicio
        .obtener(parametros)
        .then((respuesta) => {
          respuesta.forEach((tags) => {
            const payload = {
              'Número Tag': tags.NumeroTag,
              Marca: tags.Vehiculo.Marca,
              Modelo: tags.Vehiculo.Modelo,
              Anio: tags.Vehiculo.Anio,
              Placa: tags.Vehiculo.Placa,
              'Estatus Tag': tags.EstatusTag,
              Saldo: `$${parseFloat(tags.Saldo).toFixed(2)}`
            }
            dataCsv.push(payload);
          });
            var Nombre = `Tags_${moment(new Date()).format("YYMMDDHHmmss").toString()}`;
            this.appServicio.descargarArchivoCSV(dataCsv,Nombre,this.columnasXLS)
            this.cargando = false;
          }).catch((error) => {
            this.cargando = false;
          });

  }
  get NumTarjeta() { return this.formularioBusqueda.get('tarjeta'); }
  get Placa() { return this.formularioBusqueda.get('placa'); }
}
