import { Component, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatTableDataSource, MatDialog, MatPaginator } from '@angular/material';
import { SharedService } from 'src/app/servicios/shared.service';
import { ClarificationService } from 'src/app/servicios/clarification.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
    selector: 'app-aclaraciones',
    templateUrl: 'clarification.component.html',
    providers: [ ClarificationService ],
    animations: [
        trigger('detailExpand', [
          state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
          state('expanded', style({height: '*'})),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})

export class ClarificationComponent implements OnInit {

    //@ViewChild(MatPaginator) paginador: MatPaginator;
    cargando: boolean = false;
    fechaMaxima: any;
    fechaMinima: any;
    columnas: string[] = ['FechaSolicitud', 'Folio', 'NumeroTag', 'FechaCruce', 'Tarifa', 'EstatusAclaracion'];
    aclaraciones =  new MatTableDataSource([]);
    formulario: FormGroup; 
    total: number = 0;
    tablaAclaraciones: { pagina: number, limite: number, tamano: 10, index: 0 };
    constructor(private compartidoServicio: SharedService,
                private aclaracionServicio: ClarificationService,
                public dialogo: MatDialog) {this.tablaAclaraciones = { pagina: 1, limite: 10, tamano: 10, index: 0 }; }

    ngOnInit(): void {
        //this.obtenerAclaraciones();
        this.inicializarFormulario();    
        //setTimeout(() => this.aclaraciones.paginator = this.paginador);
    }
    obtenerAclaraciones(): void {
        this.cargando = true;
        const parametros = {
            tarjeta: (this.tarjeta.value) ? this.tarjeta.value : '0',
            limite: this.tablaAclaraciones.limite,
            pagina: this.tablaAclaraciones.pagina,
            inicio: (this.inicio.value) ? this.compartidoServicio.transformarObjetoFechaAString(this.inicio.value) : '0',
            fin: (this.fin.value) ? this.compartidoServicio.transformarObjetoFechaAString(this.fin.value) : '0',
        };
        this.aclaracionServicio
            .obtener(parametros)
            .then((respuesta) => {
                this.aclaraciones.data = respuesta;
                this.total = respuesta.length > 0 ? respuesta[0].TotalConteo:0;
                this.cargando = false;
            })
            .catch((error) => {
                this.cargando = false;
                this.compartidoServicio.presentarDialogoErroneo(error);
            });
    }
    inicializarFormulario() {
        this.formulario = new FormGroup({
            inicio: new FormControl(''),
            fin: new FormControl(''),
            tarjeta: new FormControl('')
        });        
      }
    cambioNumeroRegistros(evento: any) {
        this.tablaAclaraciones.index = evento.pageIndex;
        this.tablaAclaraciones.tamano = evento.pageSize;
        this.tablaAclaraciones.limite = evento.pageSize;
        this.tablaAclaraciones.pagina = evento.pageIndex + 1;
        this.obtenerAclaraciones();
      }
      get tarjeta() { return this.formulario.get('tarjeta'); }
      get inicio() { return this.formulario.get('inicio'); }
      get fin() { return this.formulario.get('fin'); }
}
