import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/servicios/shared.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-inicio',
    templateUrl: 'home.component.html'
})

export class HomeComponent implements OnInit {

    cliente: any;
    cargando: boolean = true;
    API_URL = environment.API_URL;
    documento: string = 'Calendario_Pagos_Mensual';
    mostarTarjetaCalendario: boolean = false;    
    constructor(private compartidoServicio: SharedService) { }

    ngOnInit(): void {

        this.obtenerDatos();

    }

    obtenerDatos() {

        this.compartidoServicio
            .obtenerMiInformacion()
            .then((respuesta) => {
                if(respuesta.TipoConvenio.toLowerCase() === 'postpago' && respuesta.TipoCliente.toLowerCase() === 'empresa'){
                    this.documento = 'Calendario_Pagos_decenal';
                }
                if(respuesta.TipoConvenio.toLowerCase() === 'postpago' && (respuesta.TipoCliente.toLowerCase() === 'empresa' || respuesta.TipoCliente.toLowerCase() === 'dependencia gubernamental')){
                    this.mostarTarjetaCalendario = true;
                }
                this.cliente = respuesta;
                this.cargando = false;
            })
            .catch((error) => {
                this.cargando = false;
              //  this.compartidoServicio.presentarDialogoErroneo("Error al obtener Datos Fiscales");

            })

    }

}
