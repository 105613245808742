import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SharedService } from 'src/app/servicios/shared.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-restablcerPass-dialogo',
  template: `
    <p mat-dialog-title class="text-secondary text-center">Restablecer contraseña.</p>
    <hr>
    <div mat-dialog-content class="text-center">
    
    <form [formGroup]="Formulario">

            <div class="col-lg-12 col-sm-12 col-xs-12">
                            <mat-form-field class="mat-form-control-fullwidth">
                                <input matInput type="text" placeholder="Correo Electrónico" formControlName="correo" minlength="6">
                                <mat-error *ngIf="correo.errors && correo.errors.required && (correo.touched || correo.dirty)">Campo es <strong>requerido.</strong></mat-error>                                
                            </mat-form-field>
           </div>

    </form>


    <button mat-raised-button matStepperNext type="button" [disabled]="Formulario.invalid">Restablecer</button>

    </div>
    <hr>
    <span class="text-center text-secondary m-auto d-block cursor-pointer" (click)="cerrar(data.ruta)">Cerrar</span>
  `,
})
export class RestablcerPassDialogComponent {


 Formulario: FormGroup;

    constructor(public dialogRef: MatDialogRef<SharedService>, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) { 


        this.Formulario = new FormGroup({
            correo: new FormControl('',Validators.required)
        })

    }





    cerrar(ruta: string): void {

        this.dialogRef.close();

    }

    get correo() { return this.Formulario.get("correo");}

}
