// Modules
import { NgModule } from '@angular/core';
import { PAGINAS_RUTAS } from './pages.routes';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatBottomSheetModule,
  MatListModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatCardModule,
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  MatDialogModule,
  MatSelectModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRippleModule,
  MatAutocomplete,
  MatAutocompleteBase,
  MatAutocompleteModule,
  MatTooltipModule,
} from '@angular/material';

// Components
import { PagesComponent } from './pages.component';
import { MobileMenuComponent } from '../compartido/mobile-menu/mobile-menu.component';
import { ReloadComponent } from './recarga/reload.component';
import { MovementComponent } from './movimientos/movement.component';
import { CrossesComponent } from './cruces/crosses.component';
import { ClarificationDialogComponent } from '../dialogos/clarification.dialog';
import { ClarificationComponent } from './aclaraciones/clarification.component';
import { ReloadDialogComponent } from '../dialogos/reload.dialog';
import { InvoiceComponent } from './facturacion/invoice.component';
import { HomeComponent } from './inicio/home.component';
import { PaymentDialogComponent } from '../dialogos/payment.dialog';
import { CrucesDialogComponent } from '../dialogos/cruces.dialog';
import { PaginaBancoComponent } from './pagina-banco/pagina-banco.component';
import { BusquedaCrucesComponent } from './busquedaCruces/busquedacruces.component';
import { MiPerfilCompontent } from './mi-perfil/mi-perfil.component';
import { SharedModule } from '../compartido/shared.module';
@NgModule({
  declarations: [
    PagesComponent,
    ReloadComponent,
    ClarificationComponent,
    CrossesComponent,
    MovementComponent,
    ClarificationDialogComponent,
    ReloadDialogComponent,
    InvoiceComponent,
    HomeComponent,
    PaymentDialogComponent,
    CrucesDialogComponent,
    PaginaBancoComponent,
    BusquedaCrucesComponent,
    MiPerfilCompontent,        
    
  ],
  entryComponents: [
    ClarificationDialogComponent,
    ReloadDialogComponent,
    PaymentDialogComponent,
    CrucesDialogComponent
  ],
  imports: [
    
    PAGINAS_RUTAS,    
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatListModule,
    MatProgressBarModule,
    MatCardModule,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatAutocompleteModule,    
    MatTooltipModule
    
  ]
})
export class PagesModule {}
