import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ClarificationService {

    
    constructor(private http: HttpClient) {  }

    private obtenerCabeceras(): HttpHeaders {

        const token = localStorage.getItem(environment.AUTH_CREDENTIALS_NAME);
        return new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });

    }

    async obtener(parametros: { inicio: string, fin: string, pagina: number, limite: number,tarjeta: any }): Promise<any> {

        const url = `${environment.API_URL}/Aclaraciones/ListaAclaracionesFilters/${parametros.tarjeta}/${parametros.inicio}/${parametros.fin}/${parametros.limite}/${parametros.pagina}`;///ListaAclaraciones
        const headers = this.obtenerCabeceras();

        return  await   this.http
                            .get(url, { headers })
                            .toPromise()
                            .then((respuesta: any) => (respuesta.length >= 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error en la petición'));

    }

    async crear(payload: any): Promise<any> {

        const url = `${environment.API_URL}/Aclaraciones/AltaAclaracion`;
        const headers = this.obtenerCabeceras();

        return  await   this.http
                            .post(url, JSON.stringify(payload), { headers })
                            .toPromise()
                            .then((respuesta: any) => Promise.resolve(respuesta))
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

    }

    async subir(payload: { Archivo: File, IdCruce: number }): Promise<any> {

        return await new Promise((resolve, reject) => {

            const token = localStorage.getItem(environment.AUTH_CREDENTIALS_NAME);
            const formData =  new FormData();
            const xhr = new XMLHttpRequest();
            let url = `${environment.API_URL}/Aclaraciones/AdjuntarEvidencia`; ;

            formData.append('File', payload.Archivo, payload.Archivo.name);
            formData.append('IdCruce', `${payload.IdCruce}`);          

            xhr.onreadystatechange = () => {

                if (xhr.readyState === 4 ) {

                    const respuesta = JSON.parse(xhr.response);

                    if (xhr.status === 200) {

                        resolve(respuesta);

                    } else {

                        reject(respuesta.Message);

                    }

                }

            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', `Bearer ${token}` );
            xhr.send(formData);

        });

    }


}
