import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SharedService } from 'src/app/servicios/shared.service';
import { SecurityService } from '../servicios/security.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-success-dialogo',
  template: `
    <h3 mat-dialog-title class="text-center">Terminos y Condiciones de Telpeaje</h3>
    <hr>
    <div mat-dialog-content class="text-center pt-3 pb-1">
        <iframe src="../../assets/Terminos.pdf" style="width:100%" height="400"></iframe>
    </div>
    <hr>
    <div mat-dialog-actions class="text-center">
    <button mat-button cdkFocusInitial (click)="cerrar()" class="text-secondary">Cancelar</button>
    <button mat-raised-button class="btn-login"  (click)="AltaUsuarioMigracion()">
                                    <span >Aceptar y Continuar</span></button>
</div>
  `,
})
export class TerminosCondicionesDialogComponent {


  IdUsuario;
  Password;
  CorreoElectronico;
  SUBCARPETA = environment.SUBCARPETA;

    constructor(public dialogRef: MatDialogRef<SharedService>,
      private seguridadServicio:SecurityService,
      @Inject(MAT_DIALOG_DATA) public data: any) { 


        this.IdUsuario = data.IdUsuario;
        this.Password = data.Password;
        this.CorreoElectronico = data.CorreoElectronico;
        
      }

    
    
    
    
    
    cerrar(): void {

        this.dialogRef.close();

    }


    AltaUsuarioMigracion(){


      const payload = {
        IdUsuario: this.IdUsuario,
        Password : this.Password,
        CorreoElectronico: this.CorreoElectronico
      };


      this.seguridadServicio.altaUsuarioMigracion(payload).then((respuesta) =>{
        
        this.dialogRef.close({respuesta:true});

      }).catch((error)=>{

        this.dialogRef.close({respuesta: false});

      });


    }


}
