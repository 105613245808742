import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { AdeudosComponent } from 'src/app/paginas/mis-adeudos/adeudos.cruces/cruces.componentomponent';
//import {AdeudosCrucesComponent} from '../paginas/mis-adeudos/adeudos-cruces/adeudos-cruces.component';
import {AdeudosCrucesComponent} from '../paginas/adeudos/adeudos-cruces/adeudos-cruces.component';
import { DebtsService } from 'src/app/servicios/debts.service';

@Component ({
    selector:'app-pagos-dialogo',
    template: `
    <h1 mat-dialog-title class="text-accent text-center">Detalle</h1>
    <hr>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-lg-12 col-xs-12 text-center">
                <strong>Referencia:</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-xs-12 text-center">
                {{referencia}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-12 col-xs-12 text-center">
                <strong>Fecha:</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-xs-12 text-center">
                {{fecha | date }}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-12 col-xs-12 text-center">
                <strong>Método de pago</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-xs-12 text-center">
                {{metodo}}
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-12 col-xs-12 text-center">
                <strong>Moto</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-xs-12 text-center">
                {{monto | currency : 'MXN' : '$'}} <small>MXN</small>
            </div>
        </div>
    </div>
    <hr>
    <div mat-dialog-actions class="text-center">
        <button mat-button cdkFocusInitial (click)="cerrar()" class="text-secondary">Cancelar</button>
    </div>
  `,
  providers: [ DebtsService ]
})

export class PaymentDialogComponent {

    referencia: string;
    fecha: string;
    metodo: string;
    monto: number;

    constructor(public dialogRef: MatDialogRef<AdeudosCrucesComponent>,
        private debtsService: DebtsService,
        @Inject(MAT_DIALOG_DATA) public data: any) {
            this.iniciarDialogo(data.usu.IdEstadoCuenta);
        }

    iniciarDialogo(idEstadoCuenta:number){
        this.debtsService.obtenerPagos(idEstadoCuenta)
        .then((respuesta)=>{
            if(respuesta.length>0){
                this.referencia=respuesta[0].Referencia;
                this.fecha=respuesta[0].FechaPago;
                this.metodo=respuesta[0].MetodoPago;
                this.monto=respuesta[0].MontoPago;
            }
        }).catch((error) => {
            this.dialogRef.close({ error });
        });
    }

    cerrar(): void {

    this.dialogRef.close();

    }
}