import { Component, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { SharedService } from 'src/app/servicios/shared.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CrossService } from 'src/app/servicios/cross.service';
import { ClarificationDialogComponent } from 'src/app/dialogos/clarification.dialog';
import { CatalogService } from 'src/app/servicios/catalog.service';

@Component({
    selector: 'app-busquedacruces',
    templateUrl: 'busquedacruces.component.html',
    providers: [ CrossService ]
})


export class BusquedaCrucesComponent implements OnInit {


    @ViewChild(MatPaginator) paginador: MatPaginator;
    formulario: FormGroup;
    cargando: boolean = false;
    columnas: string[] = ['Operador', 'Tramo', 'FechaMovimiento', 'NumeroTag', 'ClaseVehiculo', 'Monto', 'Accion'];
    tablaEstatus: { pagina: number, limite: number };
    cruces =  new MatTableDataSource([]);
    tablaConfiguracion: any = { pagina: 1, tamano: 10, index: 0 };
    totalCruces: number = 0;
    motivos: Array<any> = [];
    arregloCruces: Array<any> = [];


    constructor(private catalogoServicio : CatalogService
                , private cruceServicio: CrossService
                ,private compartidoServicio: SharedService
                ,private dialogo: MatDialog){}

    ngOnInit(): void {

        this.inicializarFormulario();

        this.obtenerMotivos();    
        setTimeout(() => this.cruces.paginator = this.paginador);

    }


    obtenerMotivos(): void {

        this.catalogoServicio
            .obtenerMotivos()
            .then((respuesta) => this.motivos = respuesta)
            .catch(() => false);

    }


    inicializarFormulario() {

        this.formulario = new FormGroup({
            inicio: new FormControl(''),
            fin: new FormControl('')
        });
 
    }

    obtenerDatos() {

        const payload = {
            inicio: (this.inicio.value) ? this.compartidoServicio.transformarObjectoFechaAString(this.inicio.value) : '0',
            fin: (this.fin.value) ? this.compartidoServicio.transformarObjectoFechaAString(this.fin.value) : '0',
            pagina: this.tablaConfiguracion.pagina,
            limite: this.tablaConfiguracion.tamano,
            IdTag:0
        };

        this.cargando = true;
       
    
        this.cruceServicio
            .obtener(payload)
            .then((respuesta) => {
    
                if(this.arregloCruces.length == 0){
                    this.arregloCruces = respuesta.Cruces;
                }else{
                    //this.arregloCruces.push(respuesta.Cruces);
                    respuesta.Cruces.forEach(element => {
                        this.arregloCruces.push(element);
                    });
                }


              this.cruces.data =  this.arregloCruces;
              //this.cruces.data.push(respuesta.Cruces);
              this.totalCruces = respuesta.Total
              this.cargando = false;

            })
            .catch((error) => {
    
              this.cargando = false;
    
            })
        
      }


      mostarDialogoAclaracion(elemento): void {

        const props = {
            cruce: elemento.IdCruce,
            motivos: this.motivos
        };

        const refDialogo = this.dialogo.open(ClarificationDialogComponent, { data: props, width: '500px', disableClose: true});

        refDialogo.afterClosed().subscribe((resultado) => {

            if (!resultado) { return; }
            if (resultado.error) { this.compartidoServicio.presentarDialogoErroneo(resultado.error); }
            if (resultado.respuesta) { 

                this.cruces.data = [];
                this.tablaConfiguracion.pagina = 1;
                this.obtenerDatos();
                this.compartidoServicio.presentarDialogoSatisfactorio(`Código de referencia ${resultado.respuesta}`); 
            
            }

        });

    }
      
  

     
  cambioNumeroRegistros(evento: any) {

    this.tablaConfiguracion.index = evento.pageIndex;
    this.tablaConfiguracion.tamano = evento.pageSize;
    this.tablaConfiguracion.pagina = evento.pageIndex + 1;
    this.obtenerDatos();

}


verMas(evento: any){

    this.cargando = true;
    //this.tablaConfiguracion.index = this.tablaConfiguracion.index +1;
    //this.tablaConfiguracion.tamano = evento.pageSize;
    this.tablaConfiguracion.pagina = this.tablaConfiguracion.pagina +1;
    this.obtenerDatos();
}


    get inicio() { return this.formulario.get('inicio'); }
    get fin() { return this.formulario.get('fin'); }



}