import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SharedService } from 'src/app/servicios/shared.service';

@Component({
  selector: 'app-confirmacion-dialogo',
  template: `
    <h1 mat-dialog-title class="text-primary text-center">¿Seguro?</h1>
    <hr>
    <div mat-dialog-content class="text-center pt-3 pb-1">
        <p class="text-secondary">{{ data.mensaje }}</p>
    </div>
    <hr>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial (click)="cerrar()" class="text-secondary">Cancelar</button>
        <button mat-button (click)="aceptar()" class="btn-submit-dialog">Aceptar</button>
    </div>
  `,
})
export class ConfirmationDialogComponent {

    constructor(public dialogRef: MatDialogRef<SharedService>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    cerrar(): void {

        this.dialogRef.close();

    }

    aceptar(): void {

        this.data.resultado = true;
        this.dialogRef.close(this.data);

    }

}
