import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SharedService } from './shared.service';
import { SecurityService } from './security.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private seguridadServicio: SecurityService,
                private router: Router) { }

    async canActivate(): Promise<boolean> {

        const credenciales = localStorage.getItem(environment.AUTH_CREDENTIALS_NAME);

        if (!credenciales) {

            this.router.navigate(['/autenticacion']);
            return Promise.resolve(false);

        } else {

            return Promise.resolve(true);
            // return await this.seguridadServicio
            //             .validarCredenciales(credenciales)
            //             .then((resultado) => { console.log(resultado); return Promise.resolve(true); })
            //             .catch((error) => {
            //                 console.log(error);
            //                 localStorage.removeItem(environment.AUTH_CREDENTIALS_NAME);
            //                 this.router.navigate(['/autenticacion']);
            //                 return Promise.resolve(false);

            //             });

        }

    }

}
