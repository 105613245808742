import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { SharedService } from 'src/app/servicios/shared.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MovementService } from 'src/app/servicios/movement.service';

@Component({
    selector: 'app-movimientos',
    templateUrl: 'movement.component.html',
    providers: [ MovementService ]
})

export class MovementComponent implements OnInit {

  //@ViewChild(MatPaginator) paginador: MatPaginator;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  cargando: boolean = false;
  columnas: string[] = ['FechaRegistro', 'NumeroTag', 'TipoRecarga', 'Monto', 'FolioPago', 'Accion'];
  fechaMaxima: any;
  fechaMinima: any;
  recargas =  new MatTableDataSource([]);
  formulario: FormGroup;
  total: number = 0;
  tablaRecargas: { pagina: number, limite: number, tamano: 10, index: 0 };
  facturando:boolean = false;
  constructor(private compartidoServicio: SharedService, private movimientoServicio: MovementService) {this.tablaRecargas = { pagina: 1, limite: 10, tamano: 10, index: 0 }; }
//this.tablaRecargas = { pagina: 1, limite: 10, tamano: 10, index: 0 };
  ngOnInit(): void {
        // this.fechaMaxima = this.compartidoServicio.obtenerObjetoFechaConTiempoAdicional(1, 'days');
        // this.fechaMinima = this.compartidoServicio.obtenerObjetoFechaConTiempoAdicional(1, 'days');
    this.inicializarFormulario();
    //this.obtenerDatos();  
    // setTimeout(() => this.recargas.paginator = this.paginator);

  }
  obtenerDatos() {
    this.cargando  = true;
    const parametros = {
        limite: this.tablaRecargas.limite,
        pagina: this.tablaRecargas.pagina,
        inicio: (this.inicio.value) ? this.compartidoServicio.transformarObjetoFechaAString(this.inicio.value) : '0',
        fin: (this.fin.value) ? this.compartidoServicio.transformarObjetoFechaAString(this.fin.value) : '0',
    };
    this.movimientoServicio
        .obtener(parametros)
        .then((respuesta) => {      
          if(respuesta.Total === 0)      
            {
              this.compartidoServicio.presentarDialogoSatisfactorio('La consulta no encontró coincidencias con los filtros brindados');              
          }
          this.recargas.data = respuesta.Recargas;            
          this.total = respuesta.Total;            
          this.cargando = false;
        })
        .catch((error) => {
          
                this.total = 0;
                this.recargas.data = [];
                
            this.cargando = false;
            this.compartidoServicio.presentarDialogoErroneoConRedireccion(error, '/t');
        });
  }
  cambioNumeroRegistros(evento: any) {
    this.tablaRecargas.index = evento.pageIndex;
        this.tablaRecargas.tamano = evento.pageSize;
        this.tablaRecargas.limite = evento.pageSize;
        this.tablaRecargas.pagina = evento.pageIndex + 1;
    this.obtenerDatos();
  }
  facturar(elemento: any) {
    this.compartidoServicio
        .presentarDialogoConfirmacion('Se facturará esta recarga')
        .subscribe((respuesta: boolean) => {            
            if(!respuesta) { return; } 
            this.facturando = true;           
            this.movimientoServicio
                .solicitarFacturaRecarga({ IdRecarga: elemento.IdRecarga })
                .then(() => {
                  this.facturando = false; 
                  this.compartidoServicio.presentarDialogoSatisfactorio('Generada correctamente')
                })
                .catch((error) => {
                  this.facturando = false; 
                  this.compartidoServicio.presentarDialogoErroneo(error)
                });        
        });
  }
  
  inicializarFormulario() {
    this.formulario = new FormGroup({
        inicio: new FormControl(''),
        fin: new FormControl('')
    });
    // this.inicio.valueChanges
    //     .subscribe((value) => {
    //         if (this.fin.value && value) {
    //             this.obtenerDatos();
    //         }
    //         if (!this.fin.value && !value) {
    //             this.obtenerDatos();
    //         }
    //     });
    // this.fin.valueChanges
    //     .subscribe((value) => {
    //         if (this.inicio.value && value) {
    //             this.obtenerDatos();
    //         }
    //         if (!this.inicio.value && !value) {
    //             this.obtenerDatos();
    //         }
    //     });
  }

  get inicio() { return this.formulario.get('inicio'); }
  get fin() { return this.formulario.get('fin'); }

}
