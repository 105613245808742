import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class PagosService {

  constructor(private http: HttpClient) { }
  private obtenerCabeceras(): HttpHeaders {

    return new HttpHeaders({ 'Content-Type': 'application/json' });

}

  async peticionDePago(pago:any): Promise<any> {

    const url = `${environment.API_URL}/Clientes/RespuestaPago`;
    const headers = this.obtenerCabeceras();

   return await this.http.post(url, JSON.stringify(pago),{ headers })
                        .toPromise()
                        .then((respuesta: any) => {
                          (respuesta) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error)})
                        .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexiones'));
  
  }
}
