import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatToolbarModule, MatIconModule, MatButtonModule, MatListModule, MatMenuModule } from '@angular/material';
import { NavigatorComponent } from './navegador/navigator.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { NavbarComponent } from './navbar/navbar.component';

@NgModule({
    entryComponents: [
        MobileMenuComponent
    ],
    declarations: [
        NavigatorComponent,
        NavbarComponent,
        MobileMenuComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatMenuModule
    ],
    exports: [
        NavigatorComponent,
        NavbarComponent,
        MobileMenuComponent
    ]
})

export class SharedModule {}