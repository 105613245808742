import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { SecurityService } from '../servicios/security.service';
import { SharedService } from '../servicios/shared.service';
import { environment } from 'src/environments/environment';
import { RestablcerPassDialogComponent } from '../dialogos/restablecerPass.dialog';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-autenticacion',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {

  formulario: FormGroup;
  enviado: boolean = false;
  oculto: boolean = true;

  constructor(private router: Router,
              private seguridadServicio: SecurityService,
              private compartidoServicio: SharedService,
              private dialogo: MatDialog) { }

  ngOnInit(): void {

    this.initForms();

  }

  autenticar(formulario: any): void {

    this.enviado = true;
    const payload = {
      Correo: formulario.usuario,
      Password: formulario.clave
    };

    this.seguridadServicio
        .iniciarSesion(payload)
        .then((respuesta) => {

          localStorage.setItem(environment.AUTH_CREDENTIALS_NAME, respuesta.Token);
          localStorage.setItem(environment.USER_PROFILE, JSON.stringify({ Nombre: respuesta.NombreUsuario, Rol: respuesta.RolDescripcion,Correo: formulario.usuario }));
          this.router.navigate(['/t'])
          this.enviado = false;

        })
        .catch((error) => {

          this.enviado = false;
          this.compartidoServicio.presentarDialogoErroneo(error);

        });

  }

  registroCliente(){


    Promise.all([
      this.router.navigate(['/registro'])
  ]);


  }

  initForms(): void {

    this.formulario = new FormGroup({

      usuario: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(8),
        Validators.maxLength(65)
      ]),
      clave: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(65)
      ])

    });

  }


  
  mostrarDialogo() {

    //this.dialogo.open(RestablcerPassDialogComponent, { data: this.totales, width: '500px', disableClose: true });

    this.dialogo.open(RestablcerPassDialogComponent, { data: {}, width: '400px', disableClose: true});

}

  

  get usuario() { return this.formulario.get('usuario'); }
  get clave() { return this.formulario.get('clave'); }

}
