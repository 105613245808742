import { Component, Input } from '@angular/core';
import { MobileMenuComponent } from '../mobile-menu/mobile-menu.component';
import { MatBottomSheet } from '@angular/material';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent {

  usuario: { Nombre: string, Rol: string }
  @Input() estatusUsuario:string = 'Inactivo';
  @Input() estatusUsuarioBoolean:boolean = false;

  colorEstatus:string = "green";
  constructor(private bottomSheet: MatBottomSheet) { 

    this.usuario = JSON.parse(localStorage.getItem(environment.USER_PROFILE));

  }

  openMobileMenu(): void {

    this.bottomSheet.open(MobileMenuComponent);

  }

 }