import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SharedService } from 'src/app/servicios/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-redireccionar-dialogo',
  template: `
    <h1 mat-dialog-title class="text-danger text-center">Error</h1>
    <hr>
    <div mat-dialog-content class="text-center pt-3 pb-1">
        <p class="text-secondary">{{ data.mensaje }}</p>
    </div>
    <hr>
    <span class="text-center text-secondary m-auto d-block cursor-pointer" (click)="cerrar(data.ruta)">Cerrar</span>
  `,
})
export class ErrorRedirectDialogComponent {

    constructor(private router: Router, public dialogRef: MatDialogRef<SharedService>, @Inject(MAT_DIALOG_DATA) public data: any) { }

    cerrar(ruta: string): void {

        this.router.navigate([ruta]);
        this.dialogRef.close();

    }

}
