import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class DebtsService {

  constructor(private http: HttpClient) { }

  private obtenerCabeceras(): HttpHeaders {

    const token = localStorage.getItem(environment.AUTH_CREDENTIALS_NAME);
    return new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });

  }

  async obtener(parametros:{anio: string , periodo: string, pagina: number, limite: number }): Promise<any> {

    const url = `${environment.API_URL}/Clientes/EstadosCuenta/${parametros.anio}/${parametros.periodo}/${parametros.limite}/${parametros.pagina}`;
    const headers = this.obtenerCabeceras();

    return  await   this.http
                        .get(url, { headers })
                        .toPromise()
                        .then((respuesta: any) => (respuesta.ListaAdeudos.length >= 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                        .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error en la petición'));
  }

  async obtenerPagos(IdEstadoDeCuenta:number): Promise<any> {

    const url = `${environment.API_URL}/Facturacion/${IdEstadoDeCuenta}/Pagos`;
    const headers = this.obtenerCabeceras();

    return  await   this.http
                        .get(url, { headers })
                        .toPromise()
                        .then((respuesta: any) => (respuesta.length >= 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                        .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error en la petición'));
  }

  async obtenerCrucesPorEstadoCuenta(parametros:{IdEstadoCuenta:number, pagina: number, RegistrosPorPagina: number} ): Promise<any> {

    const url = `${environment.API_URL}/Facturacion/EstadosCuentaCruces/${parametros.IdEstadoCuenta}/${parametros.RegistrosPorPagina}/${parametros.pagina}`;
    const headers = this.obtenerCabeceras();
    return  await   this.http
                        .get(url, { headers })
                        .toPromise()
                        .then((respuesta: any) => (respuesta.ListaCruces.length >= 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                        .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error en la petición'));
  }
  async obtenerFondos(parametros: { filtro: string, pagina: number, limite: number }): Promise<any> {

    const url = `${environment.API_URL}/Clientes/FondoGarantia/Saldo/${parametros.filtro}/${parametros.pagina}/${parametros.limite}`;
    const headers = this.obtenerCabeceras();

    return await this.http
        .get(url, { headers })
        .toPromise()
        .then((respuesta: any) => (respuesta.Total >= 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
        .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

}

async obtenerFondosTotales(): Promise<any> {
    const url = `${environment.API_URL}/Clientes/FondoGarantia/Saldo/Totales`;
    const headers = this.obtenerCabeceras();
    return await this.http
        .get(url, { headers })
        .toPromise()
        .then((respuesta: any) => (respuesta.TotalActual >= 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
        .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

}
async recalcularFondoGarantia(payload: { IdCliente: number }): Promise<any> {
  const url = `${environment.API_URL}/Clientes/RecalculoFondoGarantia`;
  const headers = this.obtenerCabeceras();
  return await this.http
      .post(url, JSON.stringify(payload), { headers })
      .toPromise()
      .then((respuesta: any) => (respuesta) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
      .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

}
async aplicarFondoGarantiaCliente(payload: { IdCliente: number }): Promise<any> {
  const url = `${environment.API_URL}/Clientes/AplicarFondoGarantiaCliente`;
  const headers = this.obtenerCabeceras();
  return await this.http
      .post(url, JSON.stringify(payload), { headers })
      .toPromise()
      .then((respuesta: any) => (respuesta) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
      .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

}
async obtenerFondosGarantia(): Promise<any> {

  const url = `${environment.API_URL}/Clientes/FondoGarantia`;
  const headers = this.obtenerCabeceras();

  return  await   this.http
                      .get(url, { headers })
                      .toPromise()
                      .then((respuesta: any) => (respuesta.IdCliente > 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                      .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

}
async aplicarFondoGarantia() {

  const url = `${environment.API_URL}/Clientes/AplicarFondoGarantia`;
  const headers = this.obtenerCabeceras();

  return await this.http
      .post(url, JSON.stringify({}), { headers })
      .toPromise()
      .then((respuesta: any) => (respuesta) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
      .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

}
async obtenerDetallePagoFondo(parametros: { IdClienteFondoGarantia: number }) { 

  const url = `${environment.API_URL}/Clientes/${parametros.IdClienteFondoGarantia}/PagosFondo`;
  const headers = this.obtenerCabeceras();

  return await this.http
      .get(url, { headers })
      .toPromise()
      .then((respuesta: any) => (respuesta.length > 0) ? Promise.resolve(respuesta[0]) : Promise.reject(respuesta.error))
      .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

}
}
