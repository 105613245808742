import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class CrossService {

    constructor(private http: HttpClient) {  }

    private obtenerCabeceras(): HttpHeaders {

        const token = localStorage.getItem(environment.AUTH_CREDENTIALS_NAME);
        return new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });

    }
    

    async obtener(parametros: { inicio: string, fin: string, pagina: number, limite: number,IdTag: any }): Promise<any> {

        const url = `${environment.API_URL}/Cruces/${parametros.IdTag}/${parametros.limite}/${parametros.pagina}/${parametros.inicio}/${parametros.fin}`;
        const headers = this.obtenerCabeceras();

        return  await   this.http
                            .get(url, { headers })
                            .toPromise()
                            .then((respuesta: any) => (respuesta.Cruces.length >= 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error en la petición'));

    }
    async validarAclaracion(parametros: { inicio: string, fin: string,IdCruce: any }): Promise<any> {

        const url = `${environment.API_URL}/Cruces/ValidarAclaracion`;
        const headers = this.obtenerCabeceras();

        return  await   this.http
                            .post(url, JSON.stringify(parametros), { headers })
                            .toPromise()
                            .then((respuesta: any) => Promise.resolve(respuesta))
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));


    }
}
