import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Platform } from '@angular/cdk/platform';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { SuccessDialogComponent } from '../dialogos/success.dialog';
import { SuccessRedirectDialogComponent } from '../dialogos/success-redirect.dialog';
import { ErrorDialogComponent } from '../dialogos/error.dialog';
import { ErrorRedirectDialogComponent } from '../dialogos/error-redirect.dialog';
import { ConfirmationDialogComponent } from '../dialogos/confirmation.dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class SharedService {

    public movil = false;
    public user: any;

    constructor(platform: Platform, public dialogo: MatDialog, private http: HttpClient) {

        if (platform.IOS || platform.ANDROID) { this.movil = true; }

    }

    private obtenerCabeceras(): HttpHeaders {

        const token = localStorage.getItem(environment.AUTH_CREDENTIALS_NAME);
        return new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });

    }

    presentarDialogoSatisfactorio(mensaje: string) {

        this.dialogo.open(SuccessDialogComponent, { data: { mensaje }, width: '500px', disableClose: true});

    }

    presentarDialogoSatisfactorioConRedireccion(mensaje: string, ruta: string) {

        this.dialogo.open(SuccessRedirectDialogComponent, { data: { mensaje, ruta }, width: '500px', disableClose: true});

    }

    presentarDialogoErroneo(mensaje: string) {

        this.dialogo.open(ErrorDialogComponent, { data: { mensaje }, width: '500px', disableClose: true});

    }

    presentarDialogoErroneoConRedireccion(mensaje: string, ruta: string) {

        this.dialogo.open(ErrorRedirectDialogComponent, { data: { mensaje, ruta }, width: '500px', disableClose: true});

    }

    presentarDialogoConfirmacion(mensaje: string): Observable<any> {

        const refDialogo =  this.dialogo.open(ConfirmationDialogComponent, { data: { mensaje, resultado: false }, width: '500px', disableClose: true });
        return refDialogo.afterClosed();

    }

    obtenerHoraConTiempoAdicional(cantidad: number, unidad: any) {

        return moment().add(cantidad, unidad).format('HH:mm');

    }

    obtenerHora(): string {

        return moment().format('HH:mm');

    }

    obtenerFechaHora(): string {

        return moment().format('YYYY-MM-DD HH:mm:ss');

    }

    obtenerFechaActual(): string {

        return moment().format('YYYY-MM-DD');

    }

    obtenerObjetoFechaConTiempoAdicional(cantidad: number, unidad: any): any {

        const fecha = moment().add(cantidad, unidad).format('YYYY-MM-DD');
        const partes = fecha.split('-');
        return new Date(Number(partes[0]), Number(partes[1]) - 1, Number(partes[2]));

    }

    obtenerObjetoFechaConTiempoRestante(cantidad: number, unidad: any): any {

        const fecha = moment().subtract(cantidad, unidad).format('YYYY-MM-DD');
        const partes = fecha.split('-');
        return new Date(Number(partes[0]), Number(partes[1]), Number(partes[2]));

    }

    obtenerFechaConTiempoAdicional(cantidad: number, unidad: any): any {

        return  moment().add(cantidad, unidad).format('YYYY-MM-DD');

    }

    transformarObjetoFechaAString(fecha: Date): string {

       // return moment(fecha).format('YYYY-MM-DD');
       //return moment(fecha).format('DD-MM-YYYY');
       return moment(fecha).format('YYYYMMDD');

    }

    transformarObjectoFechaAString(fecha: any): string {

        //return moment(fecha).format('YYYY-MM-DD');
        //return moment(fecha).format('DD-MM-YYYY');
        return moment(fecha).format('YYYYMMDD');

    }  

    
    async obtenerMiInformacion(): Promise<any> {

        const url = `${environment.API_URL}/Clientes/DatosFiscales`;
        const headers = this.obtenerCabeceras();

        return  await   this.http
                            .get(url, { headers })
                            .toPromise()
                            .then((respuesta: any) => (respuesta.IdCliente > 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error en la petición'));

    }

        
async actualizaPassword(payload:any): Promise<any> {

    const url = `${environment.API_URL}/Clientes/ActualizaPass/${payload.Usuario}/${payload.Pass}/${payload.NvoPass}`;
    const headers = this.obtenerCabeceras();
  
    return  await   this.http
                        .put(url,payload, { headers })
                        .toPromise()
                        .then((respuesta: any) => (respuesta.error) ? Promise.reject(respuesta.error) : Promise.resolve(respuesta))
                        .catch((error) => (error.error) ? Promise.reject(error.error) : Promise.reject(error));
  
  }

  async infoCliente(): Promise<any> {

    const url = `${environment.API_URL}/Clientes/InfoCliente`;
    const headers = this.obtenerCabeceras();
  
    return  await   this.http
                        .get(url, { headers })
                        .toPromise()
                        .then((respuesta: any) => (respuesta.error) ? Promise.reject(respuesta.error) : Promise.resolve(respuesta))
                        .catch((error) => (error.error) ? Promise.reject(error.error) : Promise.reject(error));
  
  }

}
