import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class SecurityService {

    usuario: any;

    constructor(private http: HttpClient) {   }

    private obtenerCabeceras(): HttpHeaders {

        return new HttpHeaders({ 'Content-Type': 'application/json' });

    }

    async iniciarSesion(payload: any): Promise<any> {

        const url = `${environment.API_URL}/Login`;
        const headers = this.obtenerCabeceras();

        return  await   this.http
                            .post(url, JSON.stringify(payload), { headers })
                            .toPromise()
                            .then((respuesta: any) => (respuesta.IdUsuario > 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

    }

    async validarCredenciales(credenciales: any): Promise<boolean> {

        const url = `${environment.API_URL}/Token/Validar`;
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${credenciales}` });
        return  await   this.http
                            .get(url, { headers })
                            .toPromise()
                            .then((resultado: any) =>  resultado)
                            .catch((error) =>  error);

    }

    async obtenerMiInformacion(): Promise<any> {

        const url = `${environment.API_URL}/Usuario`;
        const credenciales = localStorage.getItem(environment.AUTH_CREDENTIALS_NAME);
        const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${credenciales}` });

        return  await   this.http
                            .get(url, { headers })
                            .toPromise()
                            .then((respuesta: any) => {

                                if (respuesta.IdUsuario > 0) {

                                    this.usuario = respuesta;
                                    return Promise.resolve(respuesta);

                                } else {

                                    return Promise.reject(respuesta.error);

                                }

                            })
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

    }



    async obtenerUsuarioMigracion(IdUsuario,Password): Promise<any> {

        const url = `${environment.API_URL}/Clientes/UsuarioMigracion/${IdUsuario}/${Password}`;
       // const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${credenciales}` });
        return  await   this.http
                            .get(url)
                            .toPromise()
                            .then((resultado: any) =>  resultado)
                            .catch((error) =>  error);

    }



    async altaUsuarioMigracion(payload:any): Promise<any> {

        const url = `${environment.API_URL}/Clientes/AltaMigracion`;
        //const headers = new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${credenciales}` });
        return  await   this.http
                            .post(url, payload)
                            .toPromise()
                            .then((resultado: any) =>  resultado)
                            .catch((error) =>  error);

    }




}
