import { RouterModule, Routes } from '@angular/router';

// Components
import { LoginComponent } from './autenticacion/login.component';
import { RegistroClienteComponent } from './registro-cliente/registro-cliente.component';

const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'autenticacion' },
  { path: 'autenticacion', component: LoginComponent },
  { path: 'registro', component: RegistroClienteComponent }
];

export const APP_ROUTES = RouterModule.forRoot(appRoutes, { useHash: true });