import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CatalogService } from 'src/app/servicios/catalog.service';
import { ReloadComponent } from '../paginas/recarga/reload.component';
import { ReloadService } from '../servicios/reload.service';
import { PagoBanco } from '../modelos/pagoBanco';
import {Router} from "@angular/router";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-recarga-dialogo',
  template: `
    <h1 mat-dialog-title class="text-accent">Recargar</h1>
    <hr>
    <div mat-dialog-content>
        <form [formGroup]="formulario">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-xs-12">
                        <mat-form-field class="mat-form-control-fullwidth">
                            <input matInput type="number" placeholder="Importe" min="1" formControlName="importe" required>
                            <mat-error *ngIf="importe.errors && importe.errors.required && (importe.touched || importe.dirty)">Campo es <strong>requerido.</strong></mat-error>
                            <mat-error *ngIf="importe.errors && importe.errors.min">Valor mínimo <strong>{{ importe.errors.min.min }}.</strong></mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial (click)="cerrar()" class="text-secondary">Cancelar</button>
        <button mat-button class="btn-submit-dialog" [disabled]="formulario.invalid || enviado" (click)="recargar()">
            <span *ngIf="!enviado">Aceptar</span>
            <span *ngIf="enviado">Guardando</span>
        </button>
    </div>
  `,
  providers: [ ReloadService ]
})

export class ReloadDialogComponent {

    formulario: FormGroup;   
    enviado: boolean = false;
    CryptoJS = require("crypto-js");

    constructor(public dialogRef: MatDialogRef<ReloadComponent>,
                private recargaServicio: ReloadService,
                private router: Router,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.formulario = new FormGroup({
            importe: new FormControl(0, [
                Validators.required,
                Validators.min(1)
            ])
        });

    }

    recargar() {
        this.enviado = false;
        const payload = {
            IdInscripcion: this.data.tarjeta.IdInscripcion,
            Monto: Number(this.importe.value),
            IdMetodoPago: 1,
            MontoComision: 0,
            IdTipoRecarga: 1,
            MetodoPago: ''
        };
        
        
        let usuario: { Nombre: string, Rol: string };
        usuario = JSON.parse(localStorage.getItem(environment.USER_PROFILE));
        let hash =this.CryptoJS.HmacSHA256((('0201'+this.data.tarjeta.IdInscripcion).toString()+this.data.tarjeta.IdCliente+Number(this.importe.value)).toString(),"llavePrueba");
        let hashInBase64 = encodeURIComponent(this.CryptoJS.enc.Base64.stringify(hash));
        
        let pago:PagoBanco = {
            s_transm: ('0201'+this.data.tarjeta.IdInscripcion).toString(),
            c_referencia: this.data.tarjeta.IdCliente,
            val_1: 0,
            t_servicio: 716,
            t_importe: Number(this.importe.value), 
            val_2: usuario.Nombre,
            val_3:1,
            val_4:1,
            val_5:1,
            val_6:'1',
            val_11:usuario.Nombre,
            val_12:'5566916847',
            val_13:hashInBase64,
            val_9:0,
            val_10:'',
            t_pago:0,
            n_autoriz:''
        };
        //sessionStorage.setItem('pagoBanco', JSON.stringify(pago));
        let url='http://54.183.44.73/Banco/#/PaginaPagoBanco/'+pago.s_transm+'/'+pago.c_referencia+'/'+pago.t_servicio+'/'+pago.t_importe+'/'+pago.val_1+'/'+pago.val_2+'/'+pago.val_3+'/'+pago.val_4+'/'+pago.val_5+'/'+pago.val_6+'/'+pago.val_11+'/'+pago.val_12+'/'+pago.val_13;
        //let url='http://localhost:4201/PaginaPagoBanco/'+JSON.stringify(pago);
        window.open(url,'_blank');
        //this.router.navigate(['/www.google.com']);
        //this.router.navigate(['/t/paginaBanco','www.google.com']);
        this.dialogRef.close();
        // this.recargaServicio
        //     .recarga(payload)
        //     .then(() => {

        //         this.enviado = false;
        //         this.dialogRef.close({ respuesta: true });

        //     })
        //     .catch((error) => {

        //         this.enviado = false;
        //         this.dialogRef.close({ error });

        //     });

    }

    cerrar(): void {

        this.dialogRef.close();

    }

    get importe() { return this.formulario.get('importe'); }

}
