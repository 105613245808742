import { Component, Input } from '@angular/core';

declare const $;

@Component({
  selector: 'app-navegador',
  template: `<div class="navigator-container">
              <div class="card card-navigator" *ngFor="let modulo of modulos" [ngClass]="{'hidden': modulo.visible == 'false'}" routerLink="{{ modulo.url }}" routerLinkActive="active">
                <div class="card-body text-center">
                  <i class="material-icons d-block">{{ modulo.icono }}</i>
                  <span><small [innerHTML]="modulo.titulo"></small></span>
                </div>
              </div>
            </div>`
})

export class NavigatorComponent {

  @Input() modulos: Array<any>;

  constructor() { }

 }
