import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CrossesComponent } from '../paginas/cruces/crosses.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClarificationService } from '../servicios/clarification.service';
import { SharedService } from '../servicios/shared.service';

@Component({
  selector: 'app-aclaracion-dialogo',
  template: `
    <h1 mat-dialog-title class="text-warning text-center">Aclaración</h1>
    <hr>
    <div mat-dialog-content class="text-center pt-3 pb-1">
        <form [formGroup]="formulario">
            <mat-form-field class="mat-form-control">
                <mat-label>Motivo</mat-label>
                <mat-select formControlName="motivo" required>
                <mat-option *ngFor="let motivo of motivos" [value]="motivo.IdMotivoAclaracion">
                    {{ motivo.DescripcionCorta }}
                </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="mat-form-control">
                <textarea matInput formControlName="descripcion" rows="4" placeholder="Descripción"></textarea>
            </mat-form-field>
            <mat-form-field class="mat-form-control">
                <textarea matInput formControlName="observacion" rows="4" placeholder="Observación" maxlength="500" required></textarea>
                <mat-hint align="end">{{ observacion.value.length }} / 500</mat-hint>
            </mat-form-field>
            <mat-form-field class="mat-form-control">
                <input matInput type="text" placeholder="Archivo" formControlName="archivo" readonly>
                <input type="file" class="d-none" accept=".jpg, .jpeg, .png, .pdf" (change)="obtenerArchivo($event)" #documentField>
                <mat-icon matSuffix class="cursor-pointer " (click)="documentField.click()">attach_file</mat-icon>
                <mat-error *ngIf="archivo.errors && archivo.errors.required && (archivo.touched || archivo.dirty)">Campo <strong>requerido</strong></mat-error>
            </mat-form-field>                         
        </form>
    </div>
    <hr>
    <div mat-dialog-actions>
        <button mat-button cdkFocusInitial (click)="cerrar()" class="text-secondary">Cerrar</button>
        <button mat-button [disabled]="formulario.invalid || enviado" (click)="aceptar()" class="btn-submit-dialog">Enviar</button>
    </div>
  `,
    providers: [ ClarificationService ]
})
export class ClarificationDialogComponent {

    formulario: FormGroup;
    motivos: Array<any> = [];
    enviado: boolean = false;
    archivoSeleccionado: File;

    constructor(public dialogRef: MatDialogRef<CrossesComponent>,
                private aclaracionServicio: ClarificationService,
                @Inject(MAT_DIALOG_DATA) public data: any) {

                    this.motivos = data.motivos;
                    this.inicializarFormulario();

                }

    inicializarFormulario(): void {

        this.formulario = new FormGroup({
            motivo: new FormControl('', Validators.required),
            descripcion: new FormControl({ value: '', disabled: true}),
            observacion: new FormControl('', Validators.maxLength(500)),
            archivo: new FormControl('')
        });

        this.motivo.valueChanges.subscribe((value) => {

            if (value) {

                this.motivos.some((m) => {

                    if (Number(m.IdMotivoAclaracion) === Number(value)) {

                        this.descripcion.setValue(m.Descripcion);
                        return true;

                    }

                })

            }

        });

    }

    cerrar(): void {

        this.dialogRef.close();

    }

    async aceptar() {

        this.enviado = true;
        const payload = {
            Observaciones: this.observacion.value,
            CruceIdCruce: this.data.cruce,
            IdMotivoAclaracion: this.motivo.value,
            NombreEvidencia: ""
        };
   
        if (this.archivoSeleccionado) {

            this.aclaracionServicio
                .subir({ Archivo: this.archivoSeleccionado, IdCruce: this.data.cruce })
                .then((nombreArchivo) => {
                    payload.NombreEvidencia = nombreArchivo;
                    this.aclaracionServicio
                        .crear(payload)
                        .then((respuesta) => this.dialogRef.close({respuesta}))
                        .catch((error) => this.dialogRef.close({ error }));
                })
                .catch((error) => this.dialogRef.close({ error }));

        } else {

            this.aclaracionServicio
                .crear(payload)
                .then((respuesta) => this.dialogRef.close({respuesta}))
                .catch((error) => this.dialogRef.close({ error }));

        }

    }

    obtenerArchivo($event) {

        if ($event.target.files[0]) {

            if ($event.target.files[0].type === 'image/jpeg' || $event.target.files[0].type === 'image/png' || $event.target.files[0].type === 'application/pdf') {

                this.archivoSeleccionado = $event.target.files[0];
                this.archivo.setValue($event.target.files[0].name)

            } else {

                this.archivo.reset();
                this.archivoSeleccionado = undefined;

            }

        }

    }

    get motivo() { return this.formulario.get('motivo'); }
    get descripcion() { return this.formulario.get('descripcion'); }
    get observacion() { return this.formulario.get('observacion'); }
    get archivo() { return this.formulario.get('archivo'); }

}
