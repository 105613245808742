import { Component } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styles: [`.mat-bottom-sheet-container { background: #323E48 !important;} .sidenav-list-item { color: #95a5a6; }` ]
})

export class MobileMenuComponent {

  constructor(private router: Router,
              private bottomSheetRef: MatBottomSheetRef<MobileMenuComponent>) { }

  openLink(path: string): void {

    this.router.navigate([ path ]);
    this.bottomSheetRef.dismiss();
    event.preventDefault();

  }

  logout(): void {

    this.bottomSheetRef.dismiss();
    localStorage.removeItem(environment.AUTH_CREDENTIALS_NAME);
    localStorage.removeItem(environment.USER_PROFILE);
    this.router.navigate(['/autenticacion']);

  }

}
