import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReloadService {

    constructor(private http: HttpClient) {  }

    private obtenerCabeceras(): HttpHeaders {

        const token = localStorage.getItem(environment.AUTH_CREDENTIALS_NAME);
        return new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });




    }

    async obtener(payload:any): Promise<any> {

        const url = `${environment.API_URL}/Clientes/Tags/${payload.Tag}/${payload.Placa}`;
        const headers = this.obtenerCabeceras();

        return  await   this.http
                            .get(url, { headers })
                            .toPromise()
                            .then((respuesta: any) => (respuesta.length >= 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error en la petición'));

    }

    async recarga(payload: any): Promise<any> {

        const url = `${environment.API_URL}/Recarga/Tag`;
        const headers = this.obtenerCabeceras();

        return  await   this.http
                            .post(url, JSON.stringify(payload), { headers })
                            .toPromise()
                            .then((respuesta: any) => (respuesta) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

    }

    async ActivarTag(IdTag:Number): Promise<any> {

        const url = `${environment.API_URL}/Clientes/ActivarTag/${IdTag}`;
        const headers = this.obtenerCabeceras();

        return  await   this.http
                            .put(url, IdTag,{ headers })
                            .toPromise()
                            .then((respuesta: any) => (respuesta) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

    }

    async DesactivaTag(IdTag:Number): Promise<any> {

        const url = `${environment.API_URL}/Clientes/Deshabilitar/${IdTag}`;
        const headers = this.obtenerCabeceras();

        return  await   this.http
                            .put(url, IdTag,{ headers })
                            .toPromise()
                            .then((respuesta: any) => (respuesta) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error de conexión'));

    }


}
