
import { Component, OnInit, ViewChild } from '@angular/core';
import { ReloadService } from 'src/app/servicios/reload.service';
import { ReloadDialogComponent } from 'src/app/dialogos/reload.dialog';
import { SharedService } from 'src/app/servicios/shared.service';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SecurityService } from '../servicios/security.service';
import { Router } from '@angular/router';
import { TerminosCondicionesDialogComponent } from '../dialogos/terminoscondiciones.dialog';

@Component({
  selector: 'app-registro-cliente',
  templateUrl: 'registro-cliente.component.html',
  
})

export class RegistroClienteComponent implements OnInit {


  formularioMigracion : FormGroup;
  formularioEncontrado: FormGroup;
  Validando:boolean = false;
  Cliente: any;
  NombreCliente: string;
  ClienteEncontrado: boolean = false;
  Generando: boolean;

  constructor(private seguridadServicio: SecurityService,
              private compartidoServicio: SharedService,
              private router: Router,
              private dialogo: MatDialog){}

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.inicializarFormulario();
        
    }

    inicializarFormulario(){

      this.formularioMigracion = new FormGroup({
        usuario: new FormControl('',[Validators.required]),
        password: new FormControl('',[Validators.required])
      });

      this.formularioEncontrado = new FormGroup({
        correoElectronico: new FormControl('',[Validators.required,  Validators.email,]),        
      })

    }

    validarCliente() {
      this.Validando = true;



      this.seguridadServicio.obtenerUsuarioMigracion(this.usuario.value.trim(),this.password.value.trim()).then(respuesta=> {

        if(respuesta.IdCliente > 0){
          
        this.NombreCliente = respuesta.NombreCompleto;
        this.ClienteEncontrado = true;
        this.Validando = false;

        }else{

          this.compartidoServicio.presentarDialogoErroneo("No se logro encontrar algún registro con esa información");
          this.Validando = false;
        }

      }).catch((error) =>{ this.compartidoServicio.presentarDialogoErroneo("Error al obtener Registro"); this.Validando =false; });
      
    }

    AltaUsuarioMigracion(){

      this.Generando = true;
      const payload = {
        IdUsuario: this.usuario.value,
        Password : this.password.value,
        CorreoElectronico: this.correoElectronico.value,
      };


      this.seguridadServicio.altaUsuarioMigracion(payload).then((respuesta) =>{

        this.Generando = false;
            this.compartidoServicio.presentarDialogoSatisfactorio("El usuario se ha generado correctamente. Se ha enviado vía correo electrónico las credenciales para su ingreso");

            this.router.navigate(["/autenticacion"]);
      });


    }


    
mostarDialogoAplicar(): void {

  const props = {
    IdUsuario: this.usuario.value,
    Password : this.password.value,
    CorreoElectronico: this.correoElectronico.value,
  };

  const refDialogo = this.dialogo.open(TerminosCondicionesDialogComponent, { data: props, width: '1200px', disableClose: true});

  refDialogo.afterClosed().subscribe((resultado) => {

      if (!resultado) { return; }
      if (resultado.error) { this.compartidoServicio.presentarDialogoErroneo(resultado.error); }
      if (resultado.respuesta) {
     
        this.compartidoServicio.presentarDialogoSatisfactorio("El usuario se ha generado correctamente. Se ha enviado vía correo electrónico las credenciales para su ingreso");
        this.router.navigate(["/autenticacion"]);

      }else{


        this.compartidoServicio.presentarDialogoErroneo("Error al momento de generar Usuario.");

      }

  });

}



    get usuario() { return this.formularioMigracion.controls.usuario; }
    get password() { return this.formularioMigracion.controls.password; }
    get correoElectronico() {return this.formularioEncontrado.get('correoElectronico');}

}