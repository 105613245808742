import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SharedService } from 'src/app/servicios/shared.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-mi-perfil',
    templateUrl: 'mi-perfil.component.html',
    
})



export class MiPerfilCompontent implements OnInit{


    public UsuarioConectado:any ;
    public formularioPassword: FormGroup;
    oculto: boolean = true;
    ocultoNvo: boolean = true;
    ocultoConfirm: boolean = true;
    enviado:boolean;
    infoUsuario: any;


    constructor(private compartidoServicio: SharedService){

    }

    ngOnInit(): void {
        
        this.infoUsuario = JSON.parse(localStorage.getItem(environment.USER_PROFILE));

        console.log(localStorage.getItem(environment.USER_PROFILE));
        
        this.inicializarFormulario();
        this.obtenerDatos();
        
    }

    obtenerDatos(){

        this.compartidoServicio.infoCliente().then((respuesta) =>{

            this.UsuarioConectado = respuesta;


        }).catch((error) =>{

            this.compartidoServicio.presentarDialogoErroneo("Error al obtener información de Usuario");

        });


    }

    
    inicializarFormulario(){

        this.formularioPassword = new FormGroup({
          NuevaPass: new FormControl({ value: '', disabled: false }),
          ActualPass: new FormControl({ value: '', disabled: false }),
          ConfirmarPass: new FormControl('')
        });


    }

    actualizarPass(){

        const payload = {
          Usuario: this.infoUsuario.Correo,
          Pass: this.formularioPassword.value.ActualPass,
          NvoPass: this.formularioPassword.value.NuevaPass
        }
  
        var NvoPassword = this.formularioPassword.value.NuevaPass;
        var confirmarPassword = this.formularioPassword.value.ConfirmarPass;
  
        switch(NvoPassword != confirmarPassword){
  
          case true:
              this.compartidoServicio.presentarDialogoErroneo("Las contraseñas no coinciden, favor de verificar.");
  
          break;
  
  
          case false:
  
          this.compartidoServicio.actualizaPassword(payload).then((respuesta) => 
          {
          this.compartidoServicio.presentarDialogoSatisfactorio('Actualizado Correctamente');
          this.formularioPassword.reset();
          }).catch((error)=> {
  console.log(error);
            this.compartidoServicio.presentarDialogoErroneo(error.Message);
  
          });
  
          break;
  
  
        }
  
      }
  
  
      get NuevaPass() { return this.formularioPassword.get('NuevaPass'); }
      get ActualPass() { return this.formularioPassword.get('ActualPass'); }
      get ConfirmarPass() { return this.formularioPassword.get('ConfirmarPass'); }


}
