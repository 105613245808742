import { RouterModule, Routes } from '@angular/router';

// Components
import { PagesComponent } from './pages.component';
//import { AdeudosComponent } from './mis-adeudos/adeudos.cruces/cruces.componentomponent';
import { ReloadComponent } from './recarga/reload.component';
import { MovementComponent } from './movimientos/movement.component';
import { CrossesComponent } from './cruces/crosses.component';
import { ClarificationComponent } from './aclaraciones/clarification.component';
import { InvoiceComponent } from './facturacion/invoice.component';
import { HomeComponent } from './inicio/home.component';
import { AuthGuard } from '../servicios/auth.guard';
import { PaginaBancoComponent } from './pagina-banco/pagina-banco.component';
import { MiPerfilCompontent } from './mi-perfil/mi-perfil.component';
//import { MisAdeudosComponent } from './mis-adeudos/mis-adeudos.componen';

const paginasRutas: Routes = [
    {
        path: 't',
        component: PagesComponent,
        canActivate: [ AuthGuard ],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'inicio'
            },
            {
                path: 'inicio',
                component: HomeComponent,
            },
            {
                path: 'recargas',
                component: ReloadComponent,
            },
            {
                path: 'cruces',
                component: CrossesComponent,
            },
            {
                path: 'aclaraciones',
                component: ClarificationComponent,
            },
            {
                path: 'movimientos',
                component: MovementComponent
            },
            {
                path: 'facturas',
                component: InvoiceComponent
            },
            // {
            //     path: 'adeudos',
            //     component: AdeudosComponent
            // },
            {
                path: 'adeudos',
                loadChildren: './adeudos/adeudos.module#AdeudosModule'
            },            
            {
                path:'paginaBanco',
                component: PaginaBancoComponent
            }
            ,
            {
                path: 'MiPerfil',
                component: MiPerfilCompontent
            }
            // ,
            // {
            //     path: '**',
            //     pathMatch: 'full',
            //     redirectTo: 'inicio'
            // }
        ]
    }
];

export const PAGINAS_RUTAS = RouterModule.forChild(paginasRutas);
