
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { SharedService } from 'src/app/servicios/shared.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InvoiceService } from 'src/app/servicios/invoice.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-factura',
    templateUrl: 'invoice.component.html',
    providers: [ InvoiceService ]
})

export class InvoiceComponent implements OnInit {

    //@ViewChild(MatPaginator) paginador: MatPaginator;
    formulario: FormGroup;
    columnas: string[] = ['Fecha', 'Serie', 'Folio', 'Tipo', 'Importe', 'Impuesto', 'Total', 'Archivos'];
    facturas =  new MatTableDataSource([]);
    total: number = 0;
    peticionNumero: number = 0;
    cargando: boolean = false;
    API_URL = environment.API_URL;
    tablaFacturas: { pagina: number, limite: number, tamano: 10, index: 0 };
    constructor(private compartidoServicio: SharedService,
                private facturaServicio: InvoiceService) {this.tablaFacturas = { pagina: 1, limite: 10, tamano: 10, index: 0 }; }

    ngOnInit(): void {

        this.inicializarFormulario();
        //this.consultar(false);
        //setTimeout(() => this.facturas.paginator = this.paginador);

    }

    consultar(nuevaConsulta = false) {
        this.cargando  = true;
        if (nuevaConsulta) { this.tablaFacturas.pagina = 1; }
        if(this.inicio.value === '' || this.fin.value ==='')
        {
            this.cargando  = false;
            this.compartidoServicio.presentarDialogoSatisfactorio('Ingrese un rango de fechas para continuar.');            
            return false;
        }
        const parametros = {
            inicio: (this.inicio.value) ? this.compartidoServicio.transformarObjetoFechaAString(this.inicio.value) : '0',
            fin: (this.fin.value) ? this.compartidoServicio.transformarObjetoFechaAString(this.fin.value) : '0',
            pagina: this.tablaFacturas.pagina,
            limite: this.tablaFacturas.limite
        };

        this.facturaServicio
            .obtener(parametros)
            .then((respuesta) => {
                if(respuesta.Total === 0){
                    this.compartidoServicio.presentarDialogoSatisfactorio('No se encontraron coincidencias para los filtros seleccionados.');                    
                }
                this.facturas.data = respuesta.Facturas;
                this.total = respuesta.Total;
                this.cargando = false;

            })
            .catch((error) => {

                
                this.facturas.data = [];
                this.total = 0;
                this.cargando = false;
                this.compartidoServicio.presentarDialogoErroneoConRedireccion(error, '/t');

            });

    }

    inicializarFormulario() {

        this.formulario = new FormGroup({
            inicio: new FormControl(''),
            fin: new FormControl('')
        });

        this.inicializarListeners();

    }

    inicializarListeners() {

        // this.inicio.valueChanges
        //     .subscribe(() => {

        //         if (this.inicio.value && this.fin.value && this.inicio.valid && this.fin.valid) { this.consultar(true); }
        //         if (!this.inicio.value && !this.fin.value && this.inicio.valid && this.fin.valid) { this.consultar(true); }

        //     });

        // this.fin.valueChanges
        //     .subscribe(() => {

        //         if (this.inicio.value && this.fin.value && this.inicio.valid && this.fin.valid) { this.consultar(true); }
        //         if (!this.inicio.value && !this.fin.value && this.inicio.valid && this.fin.valid) { this.consultar(true); }

        //     });

    }

    aplicarFiltro() {

        this.peticionNumero =  this.peticionNumero + 1;
        const numPeticion = this.peticionNumero;

        setTimeout(() => {

            if (this.peticionNumero === numPeticion) {

                this.tablaFacturas.index = 0;
                this.tablaFacturas.pagina = 1;
                this.consultar(true);

            }

        }, 500);

    }

    cambioNumeroRegistros(evento: any) {
        

        this.tablaFacturas.index = evento.pageIndex;
        this.tablaFacturas.tamano = evento.pageSize;
        this.tablaFacturas.limite = evento.pageSize;
        this.tablaFacturas.pagina = evento.pageIndex + 1;
        this.consultar(false);

    }

    get rfc() { return this.formulario.get('rfc'); }
    get razonSocial() { return this.formulario.get('razonSocial'); }
    get inicio() { return this.formulario.get('inicio'); }
    get fin() { return this.formulario.get('fin'); }

}
