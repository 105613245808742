import { Component, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatDialog,MatTooltipModule} from '@angular/material';
import { SharedService } from 'src/app/servicios/shared.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CrossService } from 'src/app/servicios/cross.service';
import { ClarificationDialogComponent } from 'src/app/dialogos/clarification.dialog';
import { CatalogService } from 'src/app/servicios/catalog.service';
import { ReloadService } from 'src/app/servicios/reload.service';
import { AppService } from 'src/app/servicios/app.service';
import { CurrencyPipe } from '@angular/common';
import * as moment from 'moment';

@Component({
    selector: 'app-curces',
    templateUrl: 'crosses.component.html',
    providers: [CrossService, ReloadService, AppService]
})

export class CrossesComponent implements OnInit {

    formulario: FormGroup;
    cargando: boolean = false;
    columnas: string[] = ['Operador', 'Tramo', 'FechaMovimiento', 'NumeroTag', 'ClaseVehiculo', 'Monto', 'Accion','AforoManual'];
    tablaEstatus: { pagina: number, limite: number, tamano: 10, index: 0 };
    cruces = new MatTableDataSource([]);
    total: number = 0;
    motivos: Array<any> = [];
    enviado: boolean;
    Tarjetas: Array<any> = [];
    TarjetasXSL: Array<any> = [];
    columnasXLS: string[] = ['FechaMovimiento', 'NumeroTag', 'Plaza', 'ClaseVehiculo', 'Monto', 'Carril', 'Cuerpo'];

    constructor(private compartidoServicio: SharedService,
        public dialogo: MatDialog,
        private catalogoServicio: CatalogService,
        private recargaServicio: ReloadService,
        private appServicio: AppService,
        private cruceServicio: CrossService) { this.tablaEstatus = { pagina: 1, limite: 10, tamano: 10, index: 0 }; }

    ngOnInit(): void {
        this.inicializarFormulario();
        this.obtenerMotivos();
        this.obtenerTags();
    }
    obtenerMotivos(): void {
        this.catalogoServicio
            .obtenerMotivos()
            .then((respuesta) => this.motivos = respuesta)
            .catch(() => false);
    }
    obtenerTags() {
        const payload = {
            Tag: '0',
            Placa: '0',
        }
        this.recargaServicio
            .obtener(payload)
            .then((respuesta) => {
                this.Tarjetas = respuesta;
                this.cargando = false;
            })
            .catch((error) => {
                this.cargando = false;
            });
    }
    obtenerDatos(): void {
        this.cargando = true;
        var IdTag = 0;
        this.Tarjetas.some((tarjeta) => {
            if (tarjeta.NumeroTag == this.NumTarjeta.value) {
                IdTag = tarjeta.IdTag;
                return true;
            }
        });
        const parametros = {
            inicio: (this.inicio.value) ? this.compartidoServicio.transformarObjectoFechaAString(this.inicio.value) : '0',
            fin: (this.fin.value) ? this.compartidoServicio.transformarObjectoFechaAString(this.fin.value) : '0',
            pagina: this.tablaEstatus.pagina,
            limite: this.tablaEstatus.limite,
            IdTag: IdTag
        };
        this.cruceServicio
            .obtener(parametros)
            .then((respuesta) => {
                
                if(respuesta.Total === 0)
                {
                    this.compartidoServicio.presentarDialogoSatisfactorio('La consulta no encontró coincidencias con los filtros brindados');
                }
                this.cruces.data = respuesta.Cruces;
                this.total = respuesta.Total;
                this.cargando = false;
               
            })
            .catch((error) => {

                this.cargando = false;
                this.total = 0;
                this.cruces.data = [];
                this.compartidoServicio.presentarDialogoErroneoConRedireccion(error, '/t');
            })
    }
    mostarDialogoAclaracion(elemento): void {

        const props = {
            cruce: elemento.IdCruce,
            motivos: this.motivos
        };
        const refDialogo = this.dialogo.open(ClarificationDialogComponent, { data: props, width: '500px', disableClose: true });

        refDialogo.afterClosed().subscribe((resultado) => {

            if (!resultado) { return; }
            if (resultado.error) { this.compartidoServicio.presentarDialogoErroneo(resultado.error); }
            if (resultado.respuesta) {

                this.obtenerDatos();
                this.compartidoServicio.presentarDialogoSatisfactorio(`Código de referencia ${resultado.respuesta}`);
            }
        });

    }
    obtenerMasRegistros() {
        this.tablaEstatus.pagina += 1;
        this.obtenerDatos();
    }
    inicializarFormulario() {

        this.formulario = new FormGroup({
            inicio: new FormControl(''),
            fin: new FormControl(''),
            NumTarjeta: new FormControl(''),
        });
    }
    cambioNumeroRegistros(evento: any) {

        this.tablaEstatus.index = evento.pageIndex;
        this.tablaEstatus.tamano = evento.pageSize;
        this.tablaEstatus.limite = evento.pageSize;
        this.tablaEstatus.pagina = evento.pageIndex + 1;
        this.obtenerDatos();

    }
    descargarArchivoExcel(): any {
        let dataCsv: Array<any> = [];
        this.cargando = true;
        var IdTag = 0;
        this.Tarjetas.some((tarjeta) => {
            if (tarjeta.NumeroTag == this.NumTarjeta.value) {
                IdTag = tarjeta.IdTag;
                return true;
            }
        });
        const parametros = {
            inicio: (this.inicio.value) ? this.compartidoServicio.transformarObjectoFechaAString(this.inicio.value) : '0',
            fin: (this.fin.value) ? this.compartidoServicio.transformarObjectoFechaAString(this.fin.value) : '0',
            pagina: 1,
            limite: this.total,
            IdTag: IdTag
        };

        this.cruceServicio
            .obtener(parametros)
            .then((respuesta) => {
                respuesta.Cruces.forEach((cruce) => {
                    const payload = {
                        Operador: cruce.Operador,
                        Plaza: cruce.Plaza,
                        FechaMovimiento: cruce.FechaHoraCruce,
                        NumeroTag: cruce.NumeroTag,
                        ClaseVehiculo: `${cruce.ClaveVehiculo} - ${cruce.DescripcionClave}`,
                        Monto: `$${parseFloat(cruce.Tarifa).toFixed(2)}`,
                        Carril: cruce.NumeroCarrilPlaza,
                        Cuerpo: cruce.Cuerpo
                    }
                    dataCsv.push(payload);
                });
                var Nombre = `Cruces_${moment(new Date()).format("YYMMDDHHmmss").toString()}`;
                this.appServicio.descargarArchivoCSV(dataCsv,Nombre,this.columnasXLS)
                this.cargando = false;

            })
            .catch((error) => {
                this.cargando = false;
                this.total = 0;
                this.cruces.data = [];
                this.compartidoServicio.presentarDialogoErroneoConRedireccion(error, '/t');
            });
    }
    validarAclaracion(elemento:any):void{
        const parametros = {
            inicio: (this.inicio.value) ? this.compartidoServicio.transformarObjectoFechaAString(this.inicio.value) : '0',
            fin: (this.fin.value) ? this.compartidoServicio.transformarObjectoFechaAString(this.fin.value) : '0',
            IdCruce: elemento.IdCruce
        };
        this.cruceServicio
        .validarAclaracion(parametros)
        .then((respuesta) => {

           var mensajeError : string = 'Este cruce esta en proceso de dictaminación por lo que no puede solicitarse aclaración por el momento';
            if(respuesta.PuedoReclamar && !respuesta.TieneUnaAclaracion)
                this.mostarDialogoAclaracion(elemento);
            else
                {
                    if(respuesta.TieneUnaAclaracion)
                        mensajeError = 'Este cruce ya cuenta con una aclaración';
                    this.compartidoServicio.presentarDialogoErroneo(mensajeError);    
                }
        })
        .catch((error) => {    
            this.cargando = false;
            this.total = 0;
            this.cruces.data = [];
            this.compartidoServicio.presentarDialogoErroneoConRedireccion(error, '/t');
            return false;
        })
        
    }

    get inicio() { return this.formulario.get('inicio'); }
    get fin() { return this.formulario.get('fin'); }
    get NumTarjeta() { return this.formulario.get('NumTarjeta'); }

}
