import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { SharedService } from '../servicios/shared.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html'
})

export class PagesComponent {

estatusUsuario:string="Sin estatus";
estatusUsuarioBoolean:boolean = false;
Infocliente:any;
TipoConvenio:string = "";

  constructor(private router: Router, private compartidoServicio: SharedService) {


    this.compartidoServicio.infoCliente().then((respuesta) =>{

      this.Infocliente = respuesta;
      this.TipoConvenio = this.Infocliente.TipoConvenio;
      this.estatusUsuario = this.Infocliente.EstatusCliente;
      if(this.estatusUsuario == 'Activo')
        this.estatusUsuarioBoolean = true;

    }).catch((error)=>{

      this.compartidoServicio.presentarDialogoErroneo("Error al obtener Datos de Cliente");
    });

   }

  logout() {

    localStorage.removeItem(environment.AUTH_CREDENTIALS_NAME);
    localStorage.removeItem(environment.USER_PROFILE);
    this.router.navigate(['/autenticacion'])

  }

}