import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class InvoiceService {

    constructor(private http: HttpClient) {  }

    private obtenerCabeceras(): HttpHeaders {

        const token = localStorage.getItem(environment.AUTH_CREDENTIALS_NAME);
        return new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` });

    }

    async obtener(parametros: { limite: number, pagina: number, inicio: string, fin: string }): Promise<any> {

        const url = `${environment.API_URL}/Facturacion/Facturas/${parametros.inicio}/${parametros.fin}/${parametros.limite}/${parametros.pagina}`;
        const headers = this.obtenerCabeceras();

        return  await   this.http
                            .get(url, { headers })
                            .toPromise()
                            .then((respuesta: any) => (respuesta.Facturas.length >= 0) ? Promise.resolve(respuesta) : Promise.reject(respuesta.error))
                            .catch((error) => (error.error.Message) ? Promise.reject(error.error.Message) : Promise.reject('Error en la petición'));

    }

}
