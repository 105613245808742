import { Component, OnInit } from '@angular/core';
import { PagoBanco } from 'src/app/modelos/pagoBanco';
import { PagosService } from 'src/app/servicios/pagos.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/servicios/shared.service';

@Component({
  selector: 'app-pagina-banco',
  templateUrl: './pagina-banco.component.html'
})
export class PaginaBancoComponent implements OnInit {
  
  pagoEntrante:PagoBanco;
  public displayedColumns = ['Cliente', 'Convenio', 'Importe']
  constructor(private pagosService:PagosService,
    private router: Router,
    private compartidoServicio: SharedService) { }

  ngOnInit() {
    this.pagoEntrante =JSON.parse(sessionStorage.getItem('pagoBanco'));
  }

  regresar(){
    this.router.navigate(['/t/recargas']);
  }

  enviarPeticion(){
    this.pagosService.peticionDePago(this.pagoEntrante)
    .then(()=>{
      this.compartidoServicio.presentarDialogoSatisfactorioConRedireccion('Aprobado correctamente', '/t/recargas');
    })
    .catch((error) => {
        this.compartidoServicio.presentarDialogoErroneo(error);
    });
    
  }

}
