import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MAT_DATE_LOCALE, MatDialogModule, MatPaginatorIntl, MatButtonModule } from '@angular/material';

import { SharedService } from './shared.service';
import { PagosService } from './pagos.service';

import { MatPaginatorIntlCro } from '../directivas/custom-paginator.component';
import { SecurityService } from './security.service';
import { SuccessDialogComponent } from '../dialogos/success.dialog';
import { SuccessRedirectDialogComponent } from '../dialogos/success-redirect.dialog';
import { ErrorDialogComponent } from '../dialogos/error.dialog';
import { ErrorRedirectDialogComponent } from '../dialogos/error-redirect.dialog';
import { ConfirmationDialogComponent } from '../dialogos/confirmation.dialog';
import { CatalogService } from './catalog.service';
import { InvoiceService } from './invoice.service';
import { AuthGuard } from './auth.guard';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule
  ],
  declarations: [
    SuccessDialogComponent,
    SuccessRedirectDialogComponent,
    ErrorDialogComponent,
    ErrorRedirectDialogComponent,
    ConfirmationDialogComponent
  ],
  entryComponents: [
    SuccessDialogComponent,
    SuccessRedirectDialogComponent,
    ErrorDialogComponent,
    ErrorRedirectDialogComponent,
    ConfirmationDialogComponent
  ],
  providers: [
    SharedService,
    SecurityService,
    CatalogService,
    InvoiceService,
    PagosService,
    AuthGuard,
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro}
  ]
})

export class ServiceModule { }
